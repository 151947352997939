<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Services</v-card-title>
    <v-card-subtitle class="o-60">Liste des services activés et désactivés</v-card-subtitle>
    <!--    <div class="search-list">-->
    <!--      <v-btn fab x-small color="grey lighten-3" class="elevation-x">-->
    <!--        <font-awesome-icon :icon="['fad', 'search']" size="lg" />-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <skeleton-list v-if="loading" />
    <v-list v-if="services && !loading" class="pa-0">
      <v-list-item two-line class="click-to-create" :class="$vuetify.theme.dark ? '' : 'grey lighten-4'" @click.stop="serviceForm = true">
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">Ajouter un service</v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer un nouveau service</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <empty-list v-if="services.length === 0" title="Aucun service" subtitle="Aucun service dans cet établissement." />
      <template v-for="(service, index) in services">
        <v-list-item :key="service.id" two-line @click.stop="select(service)">
          <v-list-item-content>
            <v-list-item-title class="font-alt">{{ service.label }}</v-list-item-title>
            <v-list-item-subtitle class="op-60 font-weight-light">
              {{ service.doctorCount }} {{ 'professionnel'|pluralize(service.doctorCount) }} dans ce service
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < services.length -1" :key="index" />
      </template>
      <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="5"
          color="grey"
          @input="loadServices"
        />
      </div>
    </v-list>
    <service-disable v-if="serviceDisable" :service="item" @close="closeDialog" @disable-service="disableListItem" />
    <service-form v-if="serviceForm" :service="item" @close="closeDialog" @update-service="updateListItem" @add-service="addListItem" />
  </v-card>
</template>

<script>
  import EmptyList from '@/modules/core/components/EmptyList'
  import * as services from '@/modules/services/api/services'
  import * as doctors from '@/modules/professionals/api/doctors'
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import ServiceForm from './ServiceForm'
  import ServiceDisable from './ServiceDisable'
  import {removeItem} from '@/utils/array'
  import {getIdFromIri} from '@/utils/jsonld'
  import logger from '@/services/logger'

  export default {
    name: 'ServicesList',
    components: {ServiceForm, ServiceDisable, SkeletonList, EmptyList},
    mixins: [dataDisplayers],
    data() {
      return {
        serviceDisable: false,
        serviceForm: false,
        submitting: false,
        loading: false,
        modalLoading: false,
        services: null,
        item: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0
      }
    },
    watch:{
      '$store.state.establishments.activeEstablishmentId': {
        handler() {
          this.page = 1
          this.loadServices()
        },
        immediate: true
      }
    },
    methods: {
      async loadServices() {
        let establishmentId = this.$store.state.establishments.activeEstablishmentId
        if(!establishmentId) {
          return
        }
        this.loading = true
        try {
          await services.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage,
              establishment: establishmentId
            }
          }).then( response => {
            this.services = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          }).then(async () => {
            await this.loadServiceDoctorCounters()
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      async loadServiceDoctorCounters() {
        try {
          await Promise.all(this.services.map(async (service) => {
            const params = {
              services: getIdFromIri(service['@id'])
            }
            service.doctorCount = (await doctors.list({params})).data['hydra:totalItems'] || 0
          }))
        }
        catch (e) {
          logger.error(e)
        }
      },
      closeDialog() {
        this.serviceForm = false
        this.serviceDisable = false
        this.item = null
      },
      updateListItem(data){
        if (data) {
          Object.assign(this.services[this.services.indexOf(this.item)], data)
        }
        return false
      },
      addListItem(data){
        if (data) {
          this.services.push(data)
        }
        return false
      },
      disableListItem(){
        removeItem(this.services, this.item)
      },
      select(service) {
        this.serviceForm = true
        this.item = service
      },
      remove(service) {
        this.serviceDisable = true
        this.item = service
      }
    }
  }
</script>
