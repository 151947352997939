<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="550" transition="dialog-bottom-transition" persistent>
    <v-card>
      <v-icon small color="white" class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list class="pa-0">
        <v-img class="p-abs img-header" src="@/assets/img/login-default-bg.png" min-width="250" width="100%" max-height="70px" />
        <div class="title-header">
          <v-list-item-title class="text-h5 font-weight-light white--text px-5 p-rel white--text pt-2">Service</v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5 white--text p-rel">
            {{ service ? 'Modifier les informations du service' : 'Créer un nouveau service' }}
          </v-list-item-subtitle>
        </div>
        <div class="splitter" />
        <v-form ref="services" lazy-validation class="mt-2" @submit.prevent="submit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="model.label" label="Nom du service" color="secondary" rounded outlined dense hide-details="auto" :rules="[$rules.required, $rules.maxLength(200)]" />
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="model.gamCode" label="gamCode" color="secondary" rounded outlined dense hide-details="auto" :rules="[$rules.required, $rules.maxLength(200)]" />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="px-3">
            <v-spacer />
            <v-btn x-small rounded text @click="close">
              Annuler
            </v-btn>
            <v-btn type="submit" rounded x-small color="success" :disabled="submitting" :loading="submitting">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import {create, update} from '@/modules/services/api/services'

  export default {
    name: 'ServiceForm',
    props: {
      service: Object
    },
    data() {
      return {
        items: [],
        loading: false,
        submitting: false,
        model: {
          gamCode: this.service ? this.service.gamCode : '',
          label: this.service ? this.service.label : '',
          establishment: this.$store.getters['establishments/activeEstablishment']['@id']
        }
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.services.validate()) {
          return false
        }
        try {
          this.submitting = true
          let data = {...this.model}
          data.establishment = this.$store.getters['establishments/activeEstablishment']['@id']
          let response = this.service ? await update(this.service.id, data) : await create(data)
          if(this.service) {
            this.$emit('update-service',  response.data)
          } else {
            this.$emit('add-service',  response.data)
            await this.$store.dispatch('resetStats', this.$store.state.establishments.activeEstablishmentId)
          }
          this.$emit('close')
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'success',
            title: this.service ? 'Service modifié avec succès.' : 'Service crée avec succès.'
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.submitting = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
