<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-dialog :value="true" max-width="530">
    <v-card class="color-dialog informations">
      <v-icon small class="close-submit-dialog" @click="close">$close</v-icon>
      <v-list-item class="px-3 py-2 pb-0">
        <v-img v-if="$vuetify.breakpoint.smAndUp" :src="require('@/assets/img/illustrations/validate-file.svg')"
               max-width="150px"
        />
        <v-list-item-content class="ml-4 pl-4">
          <v-list-item-title class="text-h4 font-weight-light color-title">Supprimer le service</v-list-item-title>
          <v-list-item-subtitle class="subtitle-text text-body-2 font-alt mb-1 ml-1">
            Êtes vous sur de vouloir supprimer ce service?
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="px-3">
        <v-spacer />
        <v-btn small text @click="close">Annuler</v-btn>
        <v-btn small color="secondary" :loading="loading" text @click="disableService">Confirmer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {update} from '@/modules/services/api/services'

  export default {
    name: 'ServiceDisable',
    props: {
      service: Object
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      async disableService() {
        this.loading = true
        try {
          await update(this.service.id, {active: false}).then(() => {
            this.$emit('disable-service')
            this.$emit('close')
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      },
      close() {
        this.$emit('close')
      }
    }
  }
</script>
